import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'signin', loadChildren: './signin/signin.module#SigninPageModule' },
  { path: 'reservation', loadChildren: './reservation/reservation.module#ReservationPageModule' },
  { path: 'signature-modal', loadChildren: './signature-modal/signature-modal.module#SignatureModalPageModule' },
  { path: 'signin2', loadChildren: './signin2/signin2.module#Signin2PageModule' },
  { path: 'signin3', loadChildren: './signin3/signin3.module#Signin3PageModule' },
  { path: 'popup-modal', loadChildren: './popup-modal/popup-modal.module#PopupModalPageModule' },
  { path: 'signin1', loadChildren: './signin1/signin1.module#signin1PageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
