// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};
//test server
//export const SERVER_URL = 'http://218.103.15.179/dev/vrms2';
// export const SERVER_URL = 'https://vrms2.idigitalts.com';
//Kowloon Bay
// export const SERVER_URL = 'http://10.6.1.6';
export const SERVER_URL = 'http://20.247.22.116';
 //PFL
// export const SERVER_URL = 'http://10.2.240.70/vrms2';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
