import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { SignaturePadModule } from 'angular2-signaturepad';
import { SignatureModalPageModule } from './signature-modal/signature-modal.module';
import { HttpClientModule} from '@angular/common/http';
import { Camera } from '@ionic-native/camera/ngx';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { PopupModalPageModule } from './popup-modal/popup-modal.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicStorageModule } from '@ionic/storage';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SignaturePadModule,
    SignatureModalPageModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicStorageModule.forRoot(),
    PopupModalPageModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    QRScanner,
    BarcodeScanner,
    ScreenOrientation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
