import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.page.html',
  styleUrls: ['./popup-modal.page.scss'],
})
export class PopupModalPage implements OnInit {
    type: any;
    text:any;

  constructor(
    private modalCtrl: ModalController,
    public params: NavParams,
  ) { 
    this.type = params.get('type');
    this.text = params.get('text');
  }

  ngOnInit() {
  }
}
