import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.page.html',
  styleUrls: ['./signature-modal.page.scss'],
})
export class SignatureModalPage implements OnInit {
  item = {
    signatureImage: '',
  };
  base64Str: any;
  kbytes: number;
  TermsAndConditions:any;
  Declaration:any;
  @ViewChild(SignaturePad) public signaturePad: SignaturePad;

  public signaturePadOptions: object = {
    minWidth: 2,
    canvasWidth: 570,
    canvasHeight: 300
  };

  public signatureImage: string;


  constructor(
    private modalCtrl: ModalController,
    public params: NavParams,
    public alertController: AlertController,
  ) {
    this.item = {
      signatureImage: params.get('signatureImage'),
    };
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  drawComplete() {
    if(this.TermsAndConditions == false || this.TermsAndConditions == undefined || this.Declaration == false || this.Declaration == undefined){
      this.TermsAndConditionsAlert();
    }else{
      this.item.signatureImage = this.signaturePad.toDataURL();
      // console.log(this.signaturePad);
      this.base64Str = this.item.signatureImage.split(',');
      if (this.calculateImageSize(this.base64Str[1]) < 4) {
        this.presentAlert();
      } else {
        this.modalCtrl.dismiss(this.item);
      }
    }
    
  }

  drawClear() {
    this.signaturePad.clear();
  }

  async TermsAndConditionsAlert() {
    const alert = await this.alertController.create({
        // header: 'Error',
        message: 'Please agree the Terms And Conditions and Declaration',

        cssClass: 'prompt_alert'
    });
    await alert.present();
    setTimeout(function(){
        alert.dismiss();
    }, 3000);
}

  async presentAlert() {
    const alert = await this.alertController.create({
      // header: 'Error',
      message: 'Please Resign.',
      // buttons: ['OK'],
      cssClass: 'prompt_alert'
    });

    await alert.present();
    setTimeout(function () {
      alert.dismiss();
    }, 3000);
  }

  calculateImageSize(base64String) {
    let padding, inBytes, base64StringLength;
    if (base64String.endsWith("==")) padding = 2;
    else if (base64String.endsWith("=")) padding = 1;
    else padding = 0;

    base64StringLength = base64String.length;
    console.log(base64StringLength)
    inBytes = (base64StringLength / 4) * 3 - padding;
    console.log(inBytes);
    this.kbytes = inBytes / 1000;
    return this.kbytes;
  }
}
